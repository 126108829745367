.btn {
    height: 40px;
    
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
      
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* ----- start */
    cursor: pointer;
    /* ----- end */
    width: 100%;
    border-radius: 10px;
  }
  .labelBtn {
    font-size: 14px;
    color: #333;
    font-family: Roboto-Regular;
  }
  .posRe {
    position: relative;
  }
  .absolute {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1000;
  }
  .txtOverflow {
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
  }
  .col {
    display: flex;
    flex-direction: column;
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  .alCenter {
    align-items: center;
  }
  .alStart {
    align-items: flex-start;
  }
  .alEnd {
    align-items: flex-end;
  }
  .jfStart {
    justify-content: flex-start;
  }
  .jfEnd {
    justify-content: flex-end;
  }
  .jfCenter {
    justify-content: center;
  }
  .jfBetween {
    justify-content: space-between;
  }
  .jfAround {
    justify-content: space-around;
  }
  .fWrap {
    flex-wrap: wrap;
  }
  .scrollY { overflow-y: auto; }
  .f1 { flex: 1; }
  .f2 { flex: 2; }
  .f3 { flex: 3; }
  .f4 { flex: 4; }
  .f5 { flex: 5; }
  .border { border: 1px solid #CCC }
  .brTop { border-top: 1px solid #CCC }
  .brBot { border-bottom: 1px solid #CCC; padding-bottom: 2px; }
  .col12 { width: 100%; }
  .bgWhite { background-color: #FFF; }
  .bgBlueActive { background-color: #38AFCA  }
  .brBlueActive { border: 1px solid #38AFCA  }
  .bgRed { background-color: #F30B01  }
  .bgOrange { background-color: #ED7800  }
  .rad5 { border-radius: 5px; }
  .rad10 { border-radius: 10px; }
  .rad15 { border-radius: 15px; }
  .rad20 { border-radius: 20px; }
  .radTopLeftRight15 { border-top-left-radius: 15px; border-top-right-radius: 15px;  }
  .ml5 { margin-left: 5px; }
  .ml10 { margin-left: 10px; }
  .ml20 { margin-left: 20px; }
  .mr5 { margin: 5px; }
  .mr10 { margin: 10px; }
  .mrlr10 { margin-left: 10px; margin-right: 10px; }
  .mgr10 { margin-right: 10px; }
  .mgr20 { margin-right: 20px; }
  .mt5 { margin-top: 5px; }
  .mt10 { margin-top: 10px; }
  .mt20 { margin-top: 20px; }
  .mt80 { margin-top: 80px; }
  .mb5 { margin-bottom: 5px; }
  .mb10 { margin-bottom: 10px; }
  .mb20 { margin-bottom: 20px; }
  .pd5 { padding: 5px; }
  .pd10 { padding: 10px; }
  .pd20 { padding: 20px; }
  .pdl10 { padding-left: 10px; }
  .pdlr10 { padding-left: 10px; padding-right: 10px; }
  .pdlr20 { padding-left: 20px; padding-right: 20px; }
  .pdtb10 { padding-top: 10px; padding-bottom: 10px; }
  .txtLight { font-family: Roboto-Light; }
  .txtBold { font-family: Roboto-Medium; }
  .txtRegular { font-family: Roboto-Regular; }
  .txtWhite { color: #FFF; }
  .txtGrey { color: #999999; }
  .txtCenter { text-align: center; }
  .txtError { color: #F00; }
  .txtUrl { color: #50A5F5; font-style: italic; text-decoration: underline; }
  .font10 { font-size: 10px; }
  .font13 { font-size: 13px; }
  .font14 { font-size: 14px; }
  .font16 { font-size: 16px; }
  .font18 { font-size: 18px; }
  .font20 { font-size: 20px; }
  .font22 { font-size: 22px; }
  .font28 { font-size: 28px; }
  .font32 { font-size: 32px; }
  .font36 { font-size: 36px; }
  .font40 { font-size: 40px; }
  .h30 { height: 30px; }
  .h40 { height: 40px; }
  .h80 { height: 80px; }
  .h45 { height: 45px; }
  .h25 { height: 25px; }
  .h50 { height: 50px; }
  .w25 { width: 25px;  }
  .w30 { width: 30px; }
  .w40 { width: 40px;  }
  .w80 { width: 80px;  }
  .imageFrameSmall {
    width: 80px;
    height: 80px;
    display: flex;
  }
  .imgContain {
    width: 100%;
    height: 100%;
    /* ----- start */
    object-fit: contain;
    /* ----- end */
  }
  .imgCover {
    width: 100%;
    height: 100%;
    /* ----- start */
    object-fit: cover;
    /* ----- end */
  }
  /* ----- start */
  .pointer:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  /* ----- end */
  .txtMainActive { color: #FF6600  }
  .txtBlueActive { color: #38AFCA  }
  