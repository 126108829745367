

.react-confirm-alert-body{
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
    color: #666;
    width: 260px;

}

p {
    margin-left: 12px;
}

.react-confirm-alert-button-group {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.react-confirm-alert-button-group button {
    outline: none;
    display: inline-block;
    box-sizing: border-box;
    min-width: 40%;
    max-width: 130px;
    line-height: 18px;
    margin: 5px 10px;
    padding: 9px 12px;
    border: 1px solid #C3C8CD;
    border-radius: 2px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
}

.react-confirm-alert-button-group-btn-yes {
    background: #FF6600  !important;
    color: #FFF !important;
}


.react-confirm-alert-button-group-btn-no {
    background: #fff  !important;
    color: #716a6a !important;
    /* color: #FFF !important; */
}